<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div>
    <template v-if="type==='tabcard'">
      <q-toolbar class="custom-split-tab-card bg-grey-3 q-pa-none">
        <div v-if="haveTitle"
          class="q-pl-sm q-pr-sm bg-grey-8 text-white tab-title-have"
          style="display:flex;align-items:center;;width:100px !important;min-height:40px;height:100%;">
          <!-- style="background:#B2DFDB;color:#00695C;height:100%;display:flex;align-items:center;;min-width:70px;min-height:40px;height:100%;"> -->
          <span style="font-weight:900">
            <q-icon name="info" size="xs" style="vertical-align: sub;"/>
            {{convertTitle}}
          </span>
        </div>
        <div v-if="addTab&&editable&&!disabled"
          class="q-pl-sm q-pr-sm"
          style="height:100%;display:flex;align-items:center;width:100px !important;min-height:40px;height:100%;"
          @click="add">
          <!-- 추가 -->
          <q-btn rounded size="xs" class="tab-add-btn" color="deep-orange-6" icon="add" :label="$comm.getLangLabel('LBL0000568')"/>
        </div>
        <q-tabs
          ref="compo-tab"
          :class="tabClass ? 'bg-grey-3 mainBgColorsTxt' : ''"
          class="custom-split-tab2"
          :inline-label="inlineLabel"
          :outside-arrows="outsideArrows"
          :mobile-arrows="mobileArrows"
          :align="align ? 'left' : align"
          v-model="vValue"
          narrow-indicator
          dense shrink
        >
          <keep-alive
            v-for="(tab, idx) in tabItems"
            :key="idx" >
            <template v-if="tab">
              <q-tab
                :disable="tab.disabled || (tabInCheck && !tab.check) || disabled || !editable"
                :name="tab.name"
                :class="tab.class"
                :icon="tab.icon"
                :label="$comm.getLangLabel(tab.label)"
                v-on:click="tabClick(tab)">
                <q-badge v-if="tab.badge" color="red" floating>{{tab.badge}}</q-badge>
                <div v-if="tabInCheck" :class="[(!disabled&&editable ? 'tab-check-non-disable' : ''),]">
                  <q-checkbox
                    size="xs"
                    :disable="disabled"
                    v-model="tab.check"
                    @input="(val) => changeCheckbox(val, tab)" />
                </div>
                <div v-if="isRemoveTab(tab)" :class="[(!disabled&&editable ? 'tab-check-non-disable' : ''), 'q-pl-xs']">
                  <q-btn
                    flat round
                    size="3px"
                    color="red" icon="close"
                    class=""
                    @click="remove(tab)" />
                </div>
              </q-tab>
            </template>
          </keep-alive>
          <slot name="customTab"></slot>
            <!-- <q-space v-if="addTab" /> -->
        </q-tabs>
      </q-toolbar>
      <q-tab-panels
        v-model="vValue"
        class="tabcard-panel"
        animated
        vertical
        transition-prev="jump-left"
        transition-next="jump-right"
        :keep-alive="true"
        :style="{ 'height': cusheight }"
      >
        <template v-for="(tab, idx) in tabItems">
          <template v-if="tab">
            <q-tab-panel
              :key="idx"
              :name="tab.name"
              v-on:click="tabClick(tab)">
              <keep-alive>
                <slot v-bind="tab"></slot>
              </keep-alive>
            </q-tab-panel>
            <slot name="customTabPanel"></slot>
          </template>
        </template>
      </q-tab-panels>
    </template>
    
    <q-splitter
      v-else-if="type==='vertical'"
      ref="compo-tab"
      class="custom-split-tab fit"
      :class="tabClassVertical"
      :disable="true"
      unit="px"
      v-model="splitter.data"
    >
      <template v-slot:before>
        <q-tabs
          v-model="vValue"
          vertical
          :inline-label="inlineLabel"
          :outside-arrows="outsideArrows"
          :mobile-arrows="mobileArrows"
          :class="tabClass2"
          class="custom-split-tab3"
          :align="align"
          :style="{ 'height': cusheight }"
          switch-indicator
        >
          <q-tab
            v-for="(tab, idx) in tabItems" :key="idx"
            :disable="tab.disabled || disabled"
            :name="tab.name"
            :icon="tab.icon"
            v-on:click="tabClick(tab)">
            <template v-slot:default>
              <div class="q-tab__label" v-html="$comm.getLangLabel(tab.label)"></div>
            </template>
          </q-tab>
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="vValue"
          animated
          class="custom-vertical-panels"
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
          :keep-alive="true"
          :style="{ 'height': cusheight }"
        >
          <q-tab-panel
            v-for="(tab, idx) in tabItems"
            :key="idx"
            :name="tab.name">
            <keep-alive>
              <slot v-bind="tab"></slot>
            </keep-alive>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
    
    <template v-else-if="type==='pagetop'">
      <q-tabs
        ref="compo-tab"
        class="custom-split-tab2 mainBgColorsPageTop text-white"
        :inline-label="inlineLabel"
        :outside-arrows="outsideArrows"
        :mobile-arrows="mobileArrows"
        align="left"
        v-model="vValue"
        switch-indicator
        :dense="dense"
      >
        <keep-alive
          v-for="(tab, idx) in tabItems"
          :key="idx" >
          <q-tab
            :disable="tab.disabled || disabled"
            :class="tab.class"
            :name="tab.name"
            :icon="tab.icon"
            :label="$comm.getLangLabel(tab.label)"
            v-on:click="tabClick(tab)">
            <q-badge v-if="tab.badge" color="red" floating>{{tab.badge}}</q-badge>
          </q-tab>
        </keep-alive>
      </q-tabs>
      <q-tab-panels
        v-model="vValue"
        animated
        class="custom-panels"
        vertical
        transition-prev="jump-left"
        transition-next="jump-right"
        :keep-alive="true"
        :style="{ 'height': cusheight }"
      >
        <q-tab-panel
          v-for="(tab, idx) in tabItems"
          :key="idx"
          :name="tab.name">
          <keep-alive>
            <slot v-bind="tab"></slot>
          </keep-alive>
        </q-tab-panel>
      </q-tab-panels>
    </template>

    <template v-else>
      <q-tabs
        ref="compo-tab"
        class="custom-popuptop-tab"
        :inline-label="inlineLabel"
        :outside-arrows="outsideArrows"
        :mobile-arrows="mobileArrows"
        :align="align"
        v-model="vValue"
        switch-indicator
        :dense="dense"
      >
        <keep-alive
          v-for="(tab, idx) in tabItems"
          :key="idx" >
          <q-tab
            :disable="tab.disabled || disabled"
            :class="tab.class"
            :name="tab.name"
            :icon="tab.icon"
            v-on:click="tabClick(tab)">
            <template v-slot:default>
              <div class="q-tab__label" :style="tabOptions[tab.name] ? tabOptions[tab.name].tabLabelStyle : ''" v-html="$comm.getLangLabel(tab.label)"></div>
            </template>
            <q-badge v-if="tab.badge" color="red" floating>{{tab.badge}}</q-badge>
          </q-tab>
        </keep-alive>
      </q-tabs>
      <q-tab-panels
        v-model="vValue"
        animated
        class="custom-panels"
        vertical
        transition-prev="jump-left"
        transition-next="jump-right"
        :keep-alive="true"
        :style="{ 'height': cusheight }"
      >
        <q-tab-panel
          v-for="(tab, idx) in tabItems"
          :key="idx"
          :name="tab.name">
          <keep-alive>
            <slot v-bind="tab"></slot>
          </keep-alive>
        </q-tab-panel>
      </q-tab-panels>
    </template>

  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-tab',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    tabItems: {
      type: Array,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'horizon', // horizon vertical
    },
    tabClassVertical: {
      type: String,
      default: '',
    },
    tabClass: {
      type: String,
      default: 'mainBgColors text-white shadow-2',
    },
    tabClass2: {
      type: String,
      default: 'mainBgColorsverticalTab text-white shadow-2',
    },
    align: {
      type: String,
      default: 'justify', // left center right justify
    },
    // true인 경우 icon과 label이 한 줄에 표기
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    // false인 경우 label에 화살표가 겹쳐보임
    outsideArrows: {
      type: Boolean,
      default: true,
    },
    // false인 경우 모바일에서 드래그로 옮기수 없음
    mobileArrows: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: 'auto',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tabInCheck: {
      type: Boolean,
      default: false,
    },
    addTab: {
      type: Boolean,
      default: false,
    },
    removeTab: {
      type: Boolean,
      default: false,
    },
    removeConditionCol: {
      type: String,
      default: '',
    },
    removeConditionVal: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    splitter: {
      type: Object,
      default: function() {
        return {
          data: 100
        }
      },
    },
    tabOptions: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      vValue: '',
    };
  },
  computed: {
    cusheight() {
      let ruturnText = this.$_.replace(this.height, 'px', '');
      return ruturnText === 'auto' ? ruturnText : (ruturnText - 20) + 'px'
    },
    haveTitle() {
      return this.title ? true : false;
    },
  },
  watch: {
    // todo : 부모 v-model 값이 변경되었을 경우 바인딩
    value() {
      this.vValue = this.value;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestory() {
  },
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // TODO : 부모에게 변경여부 전달
      // this.$emit('input', this.vValue);
      this.vValue = this.value;
    },
    tabClick(tab) {
      this.$emit('tabClick', tab)
      this.$emit('input', tab.name)
    },
    add() {
      this.$emit('addTab')
    },
    remove(tab) {
      this.$emit('removeTab', tab)
    },
    isRemoveTab(tab) {
      let val = true;
      if (this.removeConditionCol && this.removeConditionVal) {
        // 해당 조건일 경우 삭제하지 않음
        val = tab[this.removeConditionCol] !== this.removeConditionVal
      }
      return this.removeTab && val
    },
    changeCheckbox(val, tab) {
      this.$emit('changeCheckbox', val, tab)
    },
    setValue(value) {
      this.vValue = value
    },
  },
};
</script>
<style lang="sass">
.custom-split-tab-card
  border-bottom-width: 0px
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  .q-tab__label
    font-size: 0.80rem
    font-weight: 600
  // .q-focus-helper
  //   height: 36px !important
  .q-tab
    height: 36px
  .q-tab__content
    height: 36px
  .q-tab__icon
    width: 16px
    height: 16px
    font-size: 0.85rem
.tabcard-panel
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  border-radius: 0 0 16px 16px

.custom-split-tab
  .q-tab__label
    font-weight: 600
    white-space: normal
  .q-tab__indicator.absolute-left
    width: 5px !important
.custom-vertical-panels
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.14), 0 1px 0px -2px rgba(0, 0, 0, 0.2), 0 1px 0px 0 rgba(0, 0, 0, 0.12) !important

.custom-split-tab2
  .q-tab__indicator.absolute-top
    height: 2px !important
.custom-panels
  border-radius: 0 0 10px 10px
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important

.custom-split-tab3
  .q-tab__indicator.absolute-top
    height: 2px !important
.custom-panels
  border-radius: 0 0 10px 10px
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important


.q-splitter--vertical
  .q-splitter__separator
    width: 0px !important
  .q-splitter__before
    .q-tab__content
      padding-top: 15px !important
      padding-bottom: 15px !important
      .q-tab__label
        line-height: 20px

.q-splitter--vertical>.q-splitter__panel
  height: auto !important

.tab-check-non-disable *
  cursor: pointer !important

.tab-add-btn
  .q-btn__content > span
    font-size:12px
</style>